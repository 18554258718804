<template>
    <div class="page-body">
        <div class="table-body">
            <div class="table-header">
                <div class="handle-box">
                    <table width="100%">
                        <tr>
                            <td style="width: 100px;">
                                供应商名称：
                            </td>
                            <td>
                                <el-input v-model="searchItem.supplierName" size="small"
                                          placeholder="请输入供应商名称"></el-input>
                            </td>
                            <td style="width: 80px">
                                联系人：
                            </td>
                            <td>
                                <el-input v-model="searchItem.contact" size="small" placeholder="请输入联系人姓名"></el-input>
                            </td>
                            <td style="width: 100px">
                                联系电话：
                            </td>
                            <td>
                                <el-input v-model="searchItem.contactTel" size="small" placeholder="请输入联系电话"></el-input>
                            </td>
                            <td style="width: 300px ;text-align: center">
                                <el-button type="primary" icon="el-icon-search" size="small" @click="searchBtn">搜索
                                </el-button>
                                <el-button type="primary" icon="el-icon-refresh" size="small" @click="reset">重置
                                </el-button>
                                <el-button type="primary" icon="el-icon-circle-plus" size="small"
                                           v-if="hasAuthority('supplier_add')" @click="addSupplier">
                                    增加
                                </el-button>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
            <div class="table-info">
                <div class="table-button">
                    <div>
                        <el-table
                                :data="tableData"
                                :max-height="tableHeight"
                                style="width: 100%;height: 100%;"
                                :border="true"
                                :header-cell-style="{ background: '#EBEEF8',height: '50px', 'text-align':'center' }"
                                :cell-style="{padding:'0',height: lineHeight}">
                            <el-table-column type="index" width="64" label="排序" align="center"></el-table-column>
                            <el-table-column prop="supplierName" label="供应商名称" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="supplierBrand" label="供应商品牌" align="center"
                                             show-overflow-tooltip></el-table-column>
                            <el-table-column prop="contact" width="200" label="联系人员" align="center"></el-table-column>
                            <el-table-column prop="contactTel" width="200" label="联系电话"
                                             align="center"></el-table-column>
                            <el-table-column prop="companyBank" width="200" label="开户银行"
                                             align="center"></el-table-column>
                            <el-table-column prop="taxpayersNum" width="200" label="纳税人识别号"
                                             align="center"></el-table-column>
                            <el-table-column prop="number" width="100" label="操作" align="center">
                                <template slot-scope="scope">
                                    <div class="opertion">
                                        <i class="iconfont iconbianji" title="编辑"
                                           v-if="hasAuthority('supplier_update')"
                                           @click="editSupplier(scope.row.id)"></i>
                                        <i class="iconfont iconshanchu" title="删除"
                                           v-if="hasAuthority('supplier_delete')"
                                           @click="deleteSupplier(scope.row.id)"></i>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                <div class="table-data">
                    <Pagination :pageSize="option.pageSize" :pageTotal="totalPage" :pageCurrent.sync="option.pageIndex"
                                :pageList="pageList" @handlePageChange="pageChange" @handleSizeChange="pageSizeChange"/>
                </div>
            </div>
        </div>
        <div class="dialog-form">
            <el-dialog
                    :title="editTitle"
                    :visible.sync="editDialogVisible"
                    :close-on-click-modal="false"
                    width="30%"
                    center>
                <el-form :model="supplierForm" :rules="supplierRules" ref="supplierForm" label-width="100px"
                         class="demo-ruleForm">
                    <el-form-item size="small" label="供应商名称:" prop="supplierName">
                        <el-input size="small" v-model="supplierForm.supplierName"></el-input>
                    </el-form-item>
                    <el-form-item size="small" label="联系人:" prop="contact">
                        <el-input size="small" v-model="supplierForm.contact"></el-input>
                    </el-form-item>
                    <el-form-item size="small" label="联系电话:" prop="contactTel">
                        <el-input size="small" v-model.number="supplierForm.contactTel"></el-input>
                    </el-form-item>
                    <el-form-item size="small" label="开户行:" prop="companyBank">
                        <el-input size="small" v-model.number="supplierForm.companyBank"></el-input>
                    </el-form-item>
                    <el-form-item size="small" label="纳税人识别号:" prop="taxpayersNum">
                        <el-input size="small" v-model="supplierForm.taxpayersNum"></el-input>
                    </el-form-item>
                    <el-form-item size="small" label="银行账号:" prop="bankNumber">
                        <el-input size="small" v-model="supplierForm.bankNumber"></el-input>
                    </el-form-item>
                </el-form>
                <div class="add-brand">
                    <div class="input-body" v-for="(item,i) in brandList" :key="i">
                        <div class="text-show">
                            供应品牌{{i+1}}:
                        </div>
                        <div class="input-text">
                            <el-input v-model="item.brandName" placeholder="请输入供应品牌"
                                      size="small"></el-input>
                        </div>
                        <div class="button-style">
                            <el-button type="danger" icon="el-icon-delete" size="small" @click="deleteBrand(i)">删除
                            </el-button>
                        </div>
                    </div>
                    <div style="margin-top: 10px">
                        <el-button type="success" icon="el-icon-ok" size="small" @click="addBrand">新增品牌</el-button>
                    </div>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="cancel('supplierForm')">取 消</el-button>
                    <el-button type="primary" @click="submit('supplierForm')" :loading="isLoad">确 定</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                isLoad: false,
                pageList: [16, 20, 30, 50],
                tableHeight: '',
                tableData: [],
                brandList: [{brandName: ''}],
                option: {
                    pageSize: 16,
                    pageIndex: 11,
                },
                totalPage: 10,
                editDialogVisible: false,
                lineHeight: '',
                editTitle: '',
                searchItem: {
                    supplierName: '',
                    contact: '',
                    contactTel: '',
                    pageSize: 16,
                    pageIndex: 1,
                },
                supplierForm: {
                    id: null,
                    supplierName: '',
                    contact: '',
                    contactTel: '',
                    companyBank: '',
                    taxpayersNum: '',
                    bankNumber: '',
                    supplierBrand: ''
                },
                supplierRules: {
                    supplierName: [{required: true, message: '请输入供应商名称', trigger: 'blur'}],
                    contact: [{required: true, message: '请输入供应商联系人', trigger: 'blur'}],
                    contactTel: [{required: true, message: '请输入供应商联系电话', trigger: 'blur'}]
                }
            };
        },
        methods: {
            pageChange(option) {
                if(option != "refresh"){
                    this.option.pageIndex = option;
                    this.searchItem.pageIndex = option;
                }
                this.$api.getSupplier(this.searchItem).then(res => {
                    if (res.code == 200) {
                        for (var item of res.data.content) {
                            if (item.supplierBrand == '' || item.supplierBrand == undefined || item.supplierBrand == '') {
                                item.supplierBrand = '';
                            } else {
                                var brandArr = item.supplierBrand.split('|');
                                item.supplierBrand = brandArr.join(',')
                            }
                        }
                        this.tableData = res.data.content;
                        this.totalPage = res.data.totalElements;
                    }
                })
            },
            pageSizeChange(val) {
                this.searchItem.pageSize = val;
                this.$api.getSupplier(this.searchItem).then(res => {
                    if (res.code == 200) {
                        for (var item of res.data.content) {
                            if (item.supplierBrand == '' || item.supplierBrand == undefined || item.supplierBrand == '') {
                                item.supplierBrand = '';
                            } else {
                                var brandArr = item.supplierBrand.split('|');
                                item.supplierBrand = brandArr.join(',')
                            }
                        }
                        this.tableData = res.data.content;
                        this.totalPage = res.data.totalElements;
                    }
                })
            },
            reset() {
                this.searchItem = {
                    supplierName: '',
                    contact: '',
                    contactTel: '',
                    pageSize: 16,
                    pageIndex: 1,
                }
                this.pageChange(1);
            },
            searchBtn() {
                this.pageChange(1);
            },
            addSupplier() {
                this.editDialogVisible = true;
                this.type = 0;
                this.isLoad = false;
                this.editTitle = '新增供应商信息'
                this.brandList = [{}]
                this.supplierForm = {
                    id: null,
                    supplierName: '',
                    contact: '',
                    contactTel: '',
                    companyBank: '',
                    taxpayersNum: '',
                    bankNumber: '',
                    supplierBrand: ''
                }
            },
            cancel(formName) {
                this.$refs[formName].resetFields();
                this.editDialogVisible = false;
            },
            submit(formName) {
                this.isLoad = true;
                var brandStr = ''
                for (var item of this.brandList) {
                    if (item.brandName != '' && item.brandName != undefined) {
                        if (brandStr != '') {
                            brandStr = brandStr + '|' + item.brandName;
                        } else {
                            brandStr = brandStr + item.brandName;
                        }
                    }else {
                        continue;
                    }
                }
                if (brandStr == '' || brandStr == undefined || brandStr == 'undefined'){
                    this.supplierForm.supplierBrand = '';
                }else {
                    this.supplierForm.supplierBrand = brandStr;
                }
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        if (this.type == 0) {
                            this.$api.saveSupplier(this.supplierForm).then(res => {
                                if (res.code == 200) {
                                    this.pageChange("refresh");
                                    this.editDialogVisible = false;
                                    this.isLoad = false;
                                    this.$message.success(res.message)
                                } else {
                                    this.isLoad = false;
                                    this.$message.error(res.message)
                                }
                            })
                        } else {
                            this.$api.updateSupplier(this.supplierForm).then(res => {
                                if (res.code == 200) {
                                    this.pageChange("refresh");
                                    this.editDialogVisible = false;
                                    this.isLoad = false;
                                    this.$message.success(res.message)
                                } else {
                                    this.isLoad = false;
                                    this.$message.error(res.message)
                                }
                            })
                        }
                    } else {
                        this.isLoad = false;
                    }
                })
            },
            deleteBrand(index) {
                this.brandList.splice(index, 1)
            },
            addBrand() {
                this.brandList.push({})
            },
            editSupplier(id) {
                this.editDialogVisible = true;
                this.type = 1;
                this.isLoad = false;
                this.brandList = []
                this.$api.getSupplierById({id: id}).then(res => {
                    if (res.code == 200) {
                        this.supplierForm = {
                            id: res.data.id,
                            supplierName: res.data.supplierName,
                            contact: res.data.contact,
                            contactTel: res.data.contactTel,
                            companyBank: res.data.companyBank,
                            taxpayersNum: res.data.taxpayersNum,
                            bankNumber: res.data.bankNumber,
                            supplierBrand: res.data.supplierBrand
                        }
                        this.editTitle = "编辑《" + res.data.supplierName + "》供应商信息"
                        for (var item of res.data.supplierBrand.split("|")) {
                            var brand = {brandName: ''}
                            if (item == '' || item == undefined) {
                                brand.brandName = '';
                            } else {
                                brand.brandName = item;
                            }
                            this.brandList.push(brand);
                        }
                    }
                })
            },
            deleteSupplier(id) {
                this.$confirm('你确定要删除此供应商信息吗？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    center: true
                }).then(() => {
                    this.$api.deleteSupplier({id: id}).then(res => {
                        if (res.code == 200) {
                            this.pageChange("refresh");
                            this.$message.success(res.message)
                        } else {
                            this.$message.error(res.message)
                        }
                    })
                }).catch(() => {
                })
            }
        },
        created() {
            this.pageChange(1);
            this.tableHeight = (window.innerHeight) * 0.76
            if (window.screen.height > 900) {
                this.lineHeight = window.screen.height * 0.038 + 'px';
            } else {
                this.lineHeight = window.screen.height * 0.033 + 'px';
            }
        }
    };
</script>
<style lang="scss" scoped>
    .iconfont {
        font-family: "iconfont" !important;
        font-size: 24px;
        font-style: normal;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        margin-top: 3px;
        display: block;
        cursor: pointer;
    }

    .opertion{
        display: flex;
        justify-content: center;
    }

    .iconbianji{
        margin-right: 10px;
        color: #20a0ff;
    }
    .iconshanchu{
        margin-left: 10px;
        color: red;
    }



    .page-body {
        background-color: #FFFFFF;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .table-body {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .table-header {
        height: 6%;
        margin-top: 0.5%;
        background-color: #FFFFFF;
    }

    .handle-box {
        padding: 5px;
    }

    .table-info {
        height: 94%;
        margin-top: 5px;
        background-color: #FFFFFF;
        display: flex;
        flex-direction: column;
    }

    .table-button {
        flex: 1;
        margin: 0 1%;
    }

    .table-data {
        height: 6%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .input-body {
        display: flex;
        margin-top: 10px;
    }

    .input-text {
        width: 74%;
        margin-left: 12px;
    }

    .td-text {
        width: 90px;
        text-align: right;
    }

    .text-show {
        width: 100px;
        margin-top: 8px;
        text-align: center;
        vertical-align: middle;
        box-sizing: border-box;
    }

    .add-brand {
        text-align: center;
    }

    .button-style {
        margin: 0 10px;
    }

    td {
        text-align: right;
    }

    td {
        white-space: nowrap; /*控制单行显示*/
        overflow: hidden; /*超出隐藏*/
        text-overflow: ellipsis; /*隐藏的字符用省略号表示*/
    }
</style>